import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import gatsbyImageType from '../prop-types/image';
import { STYLES } from '../enums';
import Stack from '../components/systems/stack';
import SEO from '../components/layout/seo';

const About = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    flex-direction: column-reverse;
    margin: 5vh auto;
  }
`;

const AboutDescription = styled.div`
  width: 30vw;
  margin-right: 8vw;
  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    margin-right: 0;
    width: 90vw;
  }
`;

const ImgContainer = styled.div`
  width: 35vw;
  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    width: 90vw;
    margin-bottom: 5vh;
  }
`;

const AboutPage = ({ data }) => (
  <>
    <SEO title="Über mich" />
    <About>
      <AboutDescription>
        <Stack centered={false} gap="30px">
          <h1>ÜBER MICH</h1>
          <p>
            Nach Beendigung meines Psychologiestudiums, meiner
            Heilpraktikerausbildung für Psychotherapie und der Zusatzausbildung
            zur Fachtherapeutin für Traumatherapie bin ich nun hier, um Ihnen
            dieses Wissen anzubieten. Durch meine persönliche Reife und
            langjährigen Aus- und Weiterbildungen im Bereich von Psychotherapie
            und Coaching kann ich auf einen großen Fundus von Erfahrungen und
            Ressourcen zurückgreifen, der für Sie von Nutzen sein könnte. Bei
            der Psychologie steht der „Mensch im Mittelpunkt“. Bei mir stehen
            <b>{' Sie im Mittelpunkt'}</b>.
          </p>
          <p>
            Wenn Sie wissen möchten, welche Qualifikation ich noch habe, dann
            verwenden Sie bitte hier
            {' diesen '}
            <a
              href="https://www.praxis-landgraf.de/ueber-mich/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Link
            </a>
            , der Sie mit meiner Webseite verbinden wird.
          </p>
          <h2>
            <i>Beate Landgraf</i>
          </h2>
        </Stack>
      </AboutDescription>
      <ImgContainer>
        <Img
          fluid={data.file.childImageSharp.fluid}
          alt="Beate lächelt in die Kamera"
        />
      </ImgContainer>
    </About>
  </>
);

AboutPage.propTypes = {
  data: gatsbyImageType.isRequired,
};

export default AboutPage;

export const query = graphql`
  query Portrait_About {
    file(relativePath: { eq: "Portrait.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
